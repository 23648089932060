<template>
  <b-row>
    <b-col sm="12" md="8">
      <my-card title="Sortir">
        <template slot="body">
          <form @submit.prevent="submitFilter">
            <b-row>
              <b-col sm="12">
                <form-select :disabled="!isPusat" ref="branchSelect" @value-changed="$store.commit('openingbalances/SET_OPTION_BRANCH',$event)" url="v1/branch_select2" :queryparams="{id_regional: mainRegionalId}" v-model="filter.id_branch" label="Cabang"></form-select>
              </b-col>
              <b-col sm="6">
                <form-date :max="maxDate" v-model="filter.date_1" label="Tanggal Mulai"></form-date>
              </b-col>
              <b-col sm="6">
                <form-date :min="minDate" v-model="filter.date_2" label="Tanggal Selesai"></form-date>
              </b-col>
              <b-col sm="12">
                <form-select ref="unitSelect" @value-changed="$store.commit('openingbalances/SET_OPTION_UNIT',$event)" url="v1/item_select2" v-model="filter.id_item" label="Item"></form-select>
              </b-col>
              <b-col sm="12">
                <button-action size="sm" @clicked="submitFilter" name="Filter" featherIcon="FilterIcon"></button-action>
                <button-action size="sm" @clicked="resetFilter" name="Reset" variant="warning"></button-action>
              </b-col>
            </b-row>
          </form>
        </template>
      </my-card>
    </b-col>
    <b-col sm="12">
      <my-card title="Daftar Persediaan Awal">
        <template slot="button">
          <button-add :to="{name: 'openingbalances-add'}" variant="primary"></button-add>
        </template>
        <template slot="body">
          <data-tables ref="datatables" endpoint="v1/opening_balances" :query-params="filter" :fields="fields">
            <template v-slot:2="{item}">
              {{item[2] | mindate}}
            </template>
            <template v-slot:4="{item}">
              {{item[4] | formatNumber}} {{item[6]}}
            </template>
            <template v-slot:5="data">
              <div class="float-none">
                <button-link size="sm" :to="{name:'openingbalances-show',params:{id: data.item[5]}}" feather-icon="FolderIcon"></button-link>
                <button-link size="sm" :to="{name:'openingbalances-edit',params:{id: data.item[5]}}" feather-icon="EditIcon"></button-link>
                <button-delete @ondelete="deleteRow" :id="data.item[5]" :name="`${data.item[0]} (${data.item[1]})`"></button-delete>
              </div>
            </template>
          </data-tables>
        </template>
      </my-card>
    </b-col>
  </b-row>
</template>

<script>
import ButtonAdd from '@/my-components/ButtonAdd.vue'
import ButtonAction from '@/my-components/ButtonAction.vue'
import DataTables from '@/my-components/DataTables.vue'
import ButtonDelete from '@/my-components/ButtonDelete.vue'
import ButtonLink from '@/my-components/ButtonLink.vue'
import FormSelect from '@/my-components/FormSelect'
import FormDate from '@/my-components/FormDate'
import { mapFields } from 'vuex-map-fields'

export default {
  components:{
    ButtonAdd,
    DataTables,
    ButtonDelete,
    ButtonLink,
    FormSelect,
    FormDate,
    ButtonAction
  },
  data() {
    return {
      fields: {
        0:{sortable: true, searchable: true, label:'Kode'},
        1:{sortable: true, searchable: true, label:'Cabang'},
        2:{sortable: true, searchable: true, label:'Tanggal'},
        3:{sortable: true, searchable: true, label:'Item'},
        4:{sortable: true, searchable: true, label:'Total'},
        5:{sortable: false, searchable: false, label:''},
      }
    }
  },
  computed:{
    ...mapFields('openingbalances',['filter']),
    filterQuery(){
      return new URLSearchParams(this.filter)
    },
    userProfile(){
      return this.$store.state.auth.profile
    },
    minDate(){
      const min = this.filter.date_1
      if(!min) return null
      else return this.$moment(min).format()
    },
    maxDate(){
      const max = this.filter.date_2
      if(!max) return null
      else return this.$moment(max).format()
    }
  },
  methods:{
    firstInit(){
      this.$refs.branchSelect.initOption(this.$store.state.openingbalances.filterBranchOptions)
      this.$refs.unitSelect.initOption(this.$store.state.openingbalances.filterUnitOptions)
      if(!this.isPusat) {
        this.filter.id_branch = this.userProfile.id_branch
        this.$refs.branchSelect.initOption([{value:this.userProfile.id_branch,label:this.userProfile.branch_name}])
      }
    },
    submitFilter(){
      this.$refs.datatables.refresh()
    },
    resetFilter(){
      if(this.isPusat){
        this.filter.id_branch = ""
      }
      this.filter.id_item = ""
      this.filter.date_1 = ""
      this.filter.date_2 = ""
      this.$refs.datatables.refresh()
    },
    async deleteRow(payload){
      try {
        await this.$store.dispatch('openingbalances/destroy',payload)
        this.$refs.datatables.refresh()
        this.toastSuccess("OK","Data Berhasil Dihapus!")
      } catch (error) {
        this.handleError(error)
      }
    }
  },
  mounted(){
    this.firstInit()
  }
}
</script>

<style>

</style>